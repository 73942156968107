import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import 'normalize.css/normalize.css'
import 'uno.css'
import './style/index.scss'
import 'element-plus/theme-chalk/dark/css-vars.css'

// 支持SVG
import 'virtual:svg-icons-register'
import 'element-plus/dist/index.css'


createApp(App).use(router).use(createPinia()).mount('#app')
