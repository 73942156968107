/**
 * 路由配置
 */
import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Layout from '@/pages/Layout.vue'
//添加到此处的路由会自动添加到侧边栏
export const asideRoutes = [
  {
    path: '/designer',
    meta: { title: '设计器', hide: true },
    name: 'Designer',
    component: () => import('@/pages/designer/Designer.vue')
  },
  {
    path: '/dataItem',
    meta: { title: '数据点配置', hide: true },
    name: 'DataItem',
    component: () => import('@/pages/dataitem/index.vue')
  },
  {
    path: '/design2d',
    meta: { title: '设计管理', hide: false, icon: 'design' },
    name: 'DesignManage',
    component: () => import('@/pages/design2d/index.vue')
  },
  {
    path: '/metafile',
    meta: { title: '图元管理', hide: false, icon: 'diagram' },
    name: 'MetaFile',
    component: () => import('@/pages/metafile/index.vue')
  },
  {
    path: '/datasource',
    meta: { title: '数据源', hide: false, icon: 'datasource' },
    name: 'DataSource',
    component: () => import('@/pages/datasource/index.vue')
  },
  {
    path: '/animation',
    meta: { title: '动画实验室', hide: false, icon: 'test' },
    name: 'Animation',
    component: () => import('@/pages/demos/index.vue')
  }
]

const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/design2d'
  },
  {
    path: '/preview',
    meta: { title: '预览页面' },
    component: () => import('@/pages/preview/index.vue')
  },
  {
    path: '/running',
    meta: { title: '运行页' },
    component: () => import('@/pages/running/index.vue')
  },
  {
    path: '/',
    redirect: '/design2d',
    component: Layout,
    children: asideRoutes
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
  scrollBehavior: (to, form, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title as string
  }
  next()
})

export default router
